.Steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 3rem 0;
  width: 48rem;
}

.Steps__item {
  position: relative;
  flex: 0 0 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 1rem;
}

.Steps__image {
  position: relative;
  z-index: 10;
  width: 7.25rem;
  height: auto;
  margin-bottom: .5rem;
}

.Steps__badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 15;
  top: 0.25rem;
  left: calc(50% - 3.625rem - 0.625rem + 0.5em);
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 2.25rem;
  background-color: rgba(125, 81, 209, 0.7);
  /* background-color: #F6BD60; */
  background-color: #b0d136;
  /* background-color: #EC91D8; */
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
}

.Steps__title {
  width: 10rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding-bottom: 0.75rem;
}

.Steps__description {
  padding-top: 1rem;
  border-top: 1px dotted #c2c6d7;
  font-size: 0.85rem;
  text-align: left;
}

@media screen and (max-width: 740px) {
  .Steps {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: auto;
  }

  .Steps__item {
    flex: initial;
    margin-bottom: 2rem;
  }
}
