.Footer {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 2rem;
  width: 100vw;
  background-color: #c2c6d7;
  padding: 0 2.5rem;
  box-sizing: border-box;
  z-index: 90;
}

@media screen and (max-width: 740px) {
  .Footer {
    display: none;
  }
}
