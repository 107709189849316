div.Home {
  min-height: calc(100vh - 3em);
  margin-top: 3rem;
  box-sizing: border-box;
  overflow: hidden;
  background-image: linear-gradient(to right, #7d51d1, rgba(125, 81, 209, 0.5)),
    url('../../assets/jpg/home_background.jpg');
  background-position: center center;
  background-size: cover;
}

.Home--fixed {
  background-attachment: fixed;
}

.Home__copy {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 46rem;
  box-sizing: border-box;
  font-weight: bold;
  text-align: left;
  color: #fff;
  padding: 4rem 0;
}

.Home__display {
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.Home__lead {
  font-size: 1.25rem;
  text-transform: uppercase;
  white-space: nowrap;
}

@media screen and (max-width: 740px) {
  .Home__copy {
    width: auto;
    padding: 3rem 1rem;
    box-sizing: border-box;
  }

  .Home__display {
    font-size: 3rem;
  }

  .Home__lead {
    font-size: 0.85rem;
    white-space: normal;
  }
}
