.Prezi {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #c2c6d7;
  padding: 3rem 0;
}

.Prezi__embed {
  width: 34rem;
  height: calc(19.125rem + 36px);
}

.Prezi__container {
  position: relative;
  width: 34rem;
}

.Prezi__cover {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.Prezi__tooltip {
  position: absolute;
  top: 4rem;
  left: -7rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 7rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
}

.Prezi__arrow {
  width: 4rem;
  height: auto;
}

.Prezi__description {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 0.75rem;
  color: #663aca;
}

@media screen and (max-width: 740px) {
  .Prezi {
    padding: 3rem 1rem;
  }

  .Prezi__container {
    width: auto;
  }

  .Prezi__tooltip {
    position: static;
    width: auto;
  }

  .Prezi__arrow {
    display: none;
  }

  .Prezi__description {
    width: 100%;
    text-align: center;
  }

  .Prezi__embed {
    width: 100%;
  }
}
