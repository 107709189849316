.Logos {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 34rem;
}

.Logos__item {
  width: 7rem;
  height: 6rem;
  object-fit: contain;
}

@media screen and (min-width: 741px) {
  .Logos__item:nth-child(11n-2),
  .Logos__item:nth-child(11n-1),
  .Logos__item:nth-child(11n) {
    margin: 0 2rem;
  }
}

@media screen and (max-width: 740px) {
  .Logos {
    width: auto;
  }
}
