.BottomMenu {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.BottomMenu__item {
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: bold;
  color: #9191c7;
  margin: 0.5rem;
  cursor: pointer;
}

.BottomMenu__item--selected {
  color: #663aca;
}
