.TopMenu {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0.5rem;
}

.TopMenu__item {
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: bold;
  color: #c2c6d7;
  margin: 0.5rem;
  cursor: pointer;
}

.TopMenu__item--selected {
  color: #fff;
}

.TopMenu__toggler,
.TopMenu__sectionName {
  display: none;
}

@media screen and (max-width: 740px) {
  .TopMenu {
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    background-color: #7d51d1;
    margin: 0;
    z-index: 10;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.5s ease-out;
  }

  .TopMenu--mobileEnabled {
    transform: translate3d(0, 3rem, 0);
  }

  .TopMenu__item {
    font-size: 1rem;
  }

  .TopMenu__toggler {
    display: block;
    width: 2rem;
    text-align: right;
    align-self: center;
    font-size: 1.5rem;
    line-height: 1;
    color: #c2c6d7;
    z-index: 20;
  }

  .TopMenu__toggler--enabled {
    color: #ffffff;
  }

  .TopMenu__sectionName {
    display: block;
    margin-left: 2rem;
    font-weight: bold;
    font-size: 0.85rem;
    color: #c2c6d7;
    text-transform: uppercase;
    transition: opacity 0.5s ease-out;
  }

  .TopMenu__sectionName--enabled {
    opacity: 0;
  }
}
