.Cover {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #7D51D1;
}

.Cover__copy {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-size: 0.3125em 0.3125rem;
  padding: 1rem 0;
  width: 22rem;
}

.Cover__header {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  text-align: left;
}

.Cover__paragraph {
  font-size: 0.85rem;
  line-height: 1.5;
  font-weight: bold;
  color: #fff;
  text-align: left;
  padding: 1.5rem 0;
}

@media screen and (max-width: 740px) {
  .Cover__header {
    font-size: 2rem;
  }

  .Cover__copy {
    width: auto;
    padding: 1rem;
  }
}
