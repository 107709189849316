.Logo {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  cursor: pointer;
  z-index: 20;
}

.Logo__primary-image {
  margin-top: 0.7rem;
  margin-left: -1rem;
  height: 5rem;
  width: auto;
  transform: rotate(-180deg);
}

.Logo__secondary-image {
  margin-top: 0.7rem;
  margin-left: -1rem;
  height: 4rem;
  width: auto;
  transform: rotate(180deg);
}

.Logo__image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.Logo__image--enabled {
  opacity: 1;
  transform: rotate(0deg);
}
