.Projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-self: stretch;
}

.Projects__item {
  padding: 2rem 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  text-align: right;
}

.Projects__item--left {
  flex-direction: row-reverse;
}

.Projects__info {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.Projects__info--left {
  align-items: flex-start;
}

.Projects__box {
  position: sticky;
  top: 5rem;
  padding: 2rem 6.5rem;
  width: 10rem;
}

.Projects__title {
  font-size: 1rem;
  font-weight: bold;
  color: #663aca;
  padding-bottom: 0.75rem;
  text-align: center;
}

.Projects__client {
  font-size: 0.85rem;
  font-weight: bold;
  color: #fff;
  padding-bottom: 0.25rem;
  text-transform: uppercase;
  text-align: center;
}

.Projects__description {
  padding-top: 1rem;
  border-top: 1px dotted #fff;
  font-size: 0.85rem;
  text-align: left;
}

.Projects__media {
  flex: 1 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Projects__media--left {
  align-items: flex-end;
}

.Projects__images {
  line-height: 0;
  width: 23rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Projects__image {
  flex: 1 0 8rem;
  width: 8rem;

  object-fit: cover;
  margin: 0.5rem;
}

.Projects__image:nth-child(1) {
  flex: 1 0 100%;
  width: 100%;
}

@media screen and (max-width: 740px) {
  .Projects {
    padding: 3rem 1rem;
  }

  .Projects__item {
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-bottom: 2rem;
  }

  .Projects__box {
    width: auto;
    padding: 1rem 0;
  }

  .Projects__images {
    width: auto;
  }
}
