.Header {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  width: 100vw;
  background-color: #7d51d1;
  color: #fff;
  padding: 0 2.5rem;
  box-sizing: border-box;
  z-index: 100;
}

@media screen and (max-width: 740px) {
  .Header {
    padding: 0 1rem;
  }
}
