div.Contact {
  /* background-color: #c2c6d7; */
  /* background-color: #fff; */
  background-color: #7d51d1;
  min-height: calc(100vh - 3em);
  justify-content: space-between;
}

div.Contact::after {
  content: '';
}

.Contact__content {
  width: 34rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.Contact__data {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: uppercase;
}

.Contact__title {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  padding-bottom: 0.75rem;
  border-bottom: 1px dotted #c2c6d7;
}

.Contact__description {
  padding-top: 1rem;
  font-size: 0.85rem;
  text-align: left;
}

.Contact__logo {
  height: 10rem;
  width: auto;
}

.Contact__link {
  /* color: #663aca; */
  font-weight: bold;
}

@media screen and (max-width: 740px) {
  .Contact__content {
    box-sizing: border-box;
    flex-direction: column;
    padding: 3rem 1rem;
    width: auto;
  }

  .Contact__logo {
    height: auto;
    padding: 1rem;
  }
}
