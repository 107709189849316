/* Generated by Font Squirrel (http://www.fontsquirrel.com) on August 3, 2015 */



@font-face {
    font-family: 'geogrotesque';
    src: url('emtype_foundry_-_geogrotesque-regular-webfont.eot');
    src: url('emtype_foundry_-_geogrotesque-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('emtype_foundry_-_geogrotesque-regular-webfont.woff2') format('woff2'),
         url('emtype_foundry_-_geogrotesque-regular-webfont.woff') format('woff'),
         url('emtype_foundry_-_geogrotesque-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'geogrotesque';
    src: url('emtype_foundry_-_geogrotesque-semibold-webfont.eot');
    src: url('emtype_foundry_-_geogrotesque-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('emtype_foundry_-_geogrotesque-semibold-webfont.woff2') format('woff2'),
         url('emtype_foundry_-_geogrotesque-semibold-webfont.woff') format('woff'),
         url('emtype_foundry_-_geogrotesque-semibold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'geogrotesque1';
    src: url('emtype_foundry_-_geogrotesque-ultralight-webfont.eot');
    src: url('emtype_foundry_-_geogrotesque-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('emtype_foundry_-_geogrotesque-ultralight-webfont.woff2') format('woff2'),
         url('emtype_foundry_-_geogrotesque-ultralight-webfont.woff') format('woff'),
         url('emtype_foundry_-_geogrotesque-ultralight-webfont.ttf') format('truetype');
    font-weight: light;
    font-style: normal;

}